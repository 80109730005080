import React, {Component} from "react"
import PropTypes from "prop-types"

import {Button, CircularProgress, Grid, Snackbar, TextField} from '@material-ui/core';
import {fetchWithJWT} from "./FetchWithJWT";
import Alert from "@material-ui/lab/Alert/Alert";
import Typography from "@material-ui/core/Typography";


class ShippingSettings extends Component{
    constructor(props) {
        super(props);
        this.state = {
            shipping: props.user.shipping,
            saved: false,
            error: null
        };
        this.nameRef = React.createRef();
        this.line1Ref = React.createRef();
        this.line2Ref = React.createRef();
        this.cityRef = React.createRef();
        this.stateRef = React.createRef();
        this.zipRef = React.createRef();
        this.phoneRef = React.createRef();
    }
    /*componentDidMount(){
        fetchWithJWT(`/account`)
            .then((data) => {this.setState({ shipping: data.shipping}) });
    }*/

    updateShipping = () =>{
        let shipping = {
            name: this.nameRef.current.value,
            phone: this.phoneRef.current.value,
            address: {
                line1: this.line1Ref.current.value,
                line2: this.line2Ref.current.value,
                city: this.cityRef.current.value,
                state: this.stateRef.current.value,
                postal_code: this.zipRef.current.value,
            }
        }
        let body = JSON.stringify({shipping: shipping})
        fetchWithJWT(`account/shipping`, 'PUT', {body: body})
            .then((data)=>{
                if (data.status == 'ok') {
                    this.setState({saved: true, error: null})
                } else {
                    this.setState({error: data.message})
                }
            })
    }

    handleClose = () => {
        this.setState({saved: false})
    }

    render() {
        //console.log('ShippingSettings render')

        let shipping = this.state.shipping
        //console.log(shipping)
        return (
            <Grid container spacing={4} justify={'center'}>

                { !shipping && (<Grid item><CircularProgress color="secondary"/></Grid>)}
                { this.state.error && (<Grid item xs={12}><Alert severity="error">{this.state.error}</Alert></Grid>)}
                { shipping && (
                    <React.Fragment>
                        <Grid item xs={12} container justify={'flex-start'}>
                            <Grid item >
                                <Typography  variant="h5" component="h5" style={{fontWeight: 600}}>
                                  Shipping Address
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container>
                            <TextField
                                inputRef={this.nameRef}
                                variant="outlined"
                                label="Full Name"
                                fullWidth={true}
                                defaultValue={shipping.name}
                            />
                        </Grid>

                        <Grid item xs={12} container>
                            <TextField
                                inputRef={this.line1Ref}
                                variant="outlined"
                                label="Address Line 1"
                                fullWidth={true}
                                defaultValue={shipping.address.line1}
                            />
                        </Grid>

                        <Grid item xs={12} container >
                            <TextField
                                inputRef={this.line2Ref}
                                variant="outlined"
                                label="Address Line 2"
                                fullWidth={true}
                                defaultValue={shipping.address.line2}
                            />
                        </Grid>

                        <Grid item xs={12} container >
                            <TextField
                                inputRef={this.phoneRef}
                                variant="outlined"
                                label="Phone Number"
                                fullWidth={true}
                                defaultValue={shipping.phone}
                            />
                        </Grid>

                        <Grid item xs={6} md={6} container>
                            <TextField
                                inputRef={this.cityRef}
                                variant="outlined"
                                label="City"
                                fullWidth={true}
                                defaultValue={shipping.address.city}
                            />
                        </Grid>

                        <Grid item xs={6} md={3} container>
                            <TextField
                                inputRef={this.stateRef}
                                variant="outlined"
                                label="State"
                                fullWidth={true}
                                defaultValue={shipping.address.state}
                            />
                        </Grid>

                        <Grid item xs={12} md={3} container>
                            <TextField
                                inputRef={this.zipRef}
                                variant="outlined"
                                label="Zip"
                                fullWidth={true}
                                defaultValue={shipping.address.postal_code}
                            />
                        </Grid>

                        <Grid item xs={12} container justify={"flex-start"}>
                            <Grid item >
                                <Button variant="contained" color="secondary" onClick={() => this.updateShipping()}>UPDATE SHIPPING ADDRESS</Button>
                            </Grid>
                        </Grid>
                    </React.Fragment>

                )}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.saved}
                    autoHideDuration={2000}
                    message="Shipping address has been successfully updated"
                    onClose={this.handleClose}
                />
            </Grid>
        );
    }
};



export default ShippingSettings
